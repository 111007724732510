import React, { useState } from 'react'; 
import './login.css';
import logo from '../Engineering_Tomorrow_BackGround.jpg';
import { Box, TextField, Button, Grid } from '@mui/material';
import { TOKEN_BASE_URL } from '../config.jsx';
import { API_BASE_URL } from '../config.jsx';

function statusToString(response) {
    return `${response.status} (${response.statusText})`;
}

export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [message, setMessage] = useState();
    const [waiting, setWaiting] = useState();
    const handleSubmit = async e => {
        const request = new Request(TOKEN_BASE_URL, {
            method: 'POST',
            body: `grant_type=password&username=${encodeURI(username)}&password=${encodeURI(password)}`,
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        });
        setWaiting(true);

        let token = null;
        let valid = false;

        await fetch(request)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                console.log(response);
                throw new Error(statusToString(response));
            })
            .then(({ access_token}) => {
                token = access_token;
            })
            .catch(err => {
                setMessage(err);
            });
        
        if (token) {
            var authRequest = new Request(`${API_BASE_URL}/HEXSelector/DownloadUserStatus`, {
                method: 'GET',
                headers: new Headers({ Authorization: `Bearer ${token}`, Accept: 'application/json' }),
            });
            await fetch(authRequest)
                .then(authResponse => {
                    if (authResponse.status !== 200) {
                        console.log(authResponse);
                        throw new Error(statusToString(authResponse));
                    }
                    valid = true;
                })
                .catch(authErr => {
                    setMessage(authErr)
                });
        }
        if (token && valid) {
            setToken(token);
        }
        setWaiting(false);
    }
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            {/* <a href="https://danfoss.com"><img src={logo} alt="logo" /></a> */}
            <h2>Please login with your HEXSelector Account</h2>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, p: 1, width: '25ch' },
                }}
            >
                <Grid container spacing={2}>
                    <Grid item>
                        <TextField disabled={waiting} id="email" label="Email" type="text" onChange={(e) => setUserName(e.target.value)} />
                    </Grid>
                    <Grid item>
                        <TextField disabled={waiting} id="password" label="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
                    </Grid>
                    <Grid 
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button disabled={waiting} variant="contained" onClick={(e) => handleSubmit(e)}>Login</Button>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <a href="https://hexacthost.danfoss.com/he-auth-admin/#/preresetpassword">Forgot Password</a>
                    </Grid>
                    <Grid item>
                        {(message != null && !waiting) ? `${message}` : '' }
                    </Grid>
                </Grid>
            </Box>
            <div>
                <p>If you do not have an account you can request one <a href="https://hexacthost.danfoss.com/he-auth-admin/#/Registration" target="_blank" rel="noreferrer">here</a></p>
                <p>Your account request is subject to approval by Danfoss</p>
            </div>
        </Grid>
      )
}
